import {
  Grid,
  Column,
  SubSection,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import Ad from 'components/generic/ad'
import VerticalCardGroup from 'components/generic/vertical-card-group'
import EventListingCard from 'components/events/cards/event-listing'
import messages from 'messages/events'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { clubEventsByIdRoute } from 'lib/routes'
import ViewMoreButton from 'components/generic/view-more-button'

const ClubUpcomingEvents = ({ events }) => {
  const intl = useIntl()
  const { asPath } = useRouter()

  return (
    <SubSection
      title={intl.formatMessage(messages.upcomingEvents)}
      variant={variants.section.secondary}
    >
      <Grid lCols={12} sCols={1} sWrap>
        <Column lSpan={7}>
          <VerticalCardGroup
            pt={0}
            Button={() => (
              <ViewMoreButton {...clubEventsByIdRoute(`${asPath}/events`)}>
                {intl.formatMessage(messages.viewMoreEvents)}
              </ViewMoreButton>
            )}
          >
            {events.map((event) => (
              <EventListingCard key={event.id} event={event} />
            ))}
          </VerticalCardGroup>
        </Column>
        <Column lSpan={5}>
          <Ad
            variant={Ad.variants.MPU_General_Large}
            pb={5}
            justifyContent="flex-end"
          />
        </Column>
      </Grid>
    </SubSection>
  )
}

ClubUpcomingEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string.isRequired,
      contentUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      pick: PropTypes.object,
      artists: PropTypes.array,
    }).isRequired
  ).isRequired,
}

export default ClubUpcomingEvents
