const isValidId = (id) => {
  if (!id) return false
  const number = Number(id)
  if (Number.isNaN(number)) return false
  if (!Number.isInteger(number)) return false
  if (number < 0) return false

  return true
}

export default isValidId
