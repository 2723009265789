import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { Divider, Section } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { dark } from 'themes'
import Ad from 'components/generic/ad'
import {
  DefaultLoadingSpinner,
  useQueryResult,
} from 'components/generic/query-result-handler'
import { FullWidthImage } from 'components/generic/full-width'
import arrayHasData from 'lib/arrayHasData'
import trackingIds from 'tracking/tracking-ids'
import AD_TARGETING_KEYS from 'enums/ad-targeting-keys'
import ENTITY_TYPE from 'enums/entity-type'
import Padded, { PaddedStack } from 'components/generic/padded'
import { useAdTargetingDispatch } from 'hooks/useAdTargeting'
import useUpcomingEvents from 'hooks/useUpcomingEvents'
import Listing from 'components/shared/listing'

import Throw500 from 'components/generic/throw-500'
import Throw404 from 'components/generic/throw-404'
import MusicVenueJsonLd from './music-venue-json-ld'
import GET_VENUE from './GetVenueQuery'
import ClubDetailBar from './club-detail-bar'
import ClubNews from './club-news'
import ClubStats from './club-stats'
import ClubUpcomingEvents from './club-upcoming-events'
import ClubRaSays from './club-ra-says'
import ClubEventsArchive from './club-events-archive'
import ClubMap from './club-map'
import ClubsDetailSubNav from './ClubDetailSubNav'
import ClubDetailPageTemplate from './ClubDetailPageTemplate'
import { CLUB_PAGE_TYPE } from './clubSEOUtils'

const ClubDetailRelatedContent = dynamic(
  () =>
    import('./ClubDetailRelatedContent').then(
      (module) => module.ClubDetailRelatedContent
    ),
  { ssr: false }
)

const ClubDetail = ({ id }) => {
  const { data, error, loading, empty } = useQueryResult(GET_VENUE, {
    variables: {
      id,
    },
    dataKey: 'venue',
  })
  const {
    events: upcomingEvents,
    loading: upcomingEventsLoading,
    error: upcomingEventsError,
  } = useUpcomingEvents({ type: Listing.Aggregations.Club, id })

  if (error || upcomingEventsError) {
    return <Throw500 />
  }

  if (loading || upcomingEventsLoading) {
    return <DefaultLoadingSpinner />
  }

  if (empty) {
    return <Throw404 entityType={ENTITY_TYPE.Club} />
  }

  return <ClubDetailMarkup data={data} upcomingEvents={upcomingEvents} />
}

ClubDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const ClubDetailMarkup = ({ data, upcomingEvents }) => {
  useAdTargetingDispatch(
    buildAdTargeting(
      data.id,
      data.area.country.name,
      data.area.name,
      data.contentUrl
    )
  )

  const displayClubStats =
    data.eventCountThisYear !== 0 ||
    data.capacity !== '0' ||
    data.capacity !== '' ||
    arrayHasData(data.topArtists)

  return (
    <ClubDetailPageTemplate pageType={CLUB_PAGE_TYPE.overview} venue={data}>
      <MusicVenueJsonLd
        addressRegion={data.area?.name}
        addressCountry={data.area?.country?.isoCode}
        {...data}
      />
      <Section data-tracking-id={trackingIds.clubDetail}>
        <ThemeProvider theme={dark}>
          <ClubsDetailSubNav
            contentUrl={data.contentUrl}
            hasUpcomingEvents={arrayHasData(upcomingEvents)}
          />
          <Divider />
          <ClubDetailBar {...data} links={buildLinks(data)} />
          <Padded bg="grey.10">
            {arrayHasData(upcomingEvents) && (
              <ClubUpcomingEvents events={upcomingEvents} />
            )}
            <Ad variant={Ad.variants.InPageSmall} bg="primary" p={2} />
          </Padded>
        </ThemeProvider>
        <PaddedStack>
          {displayClubStats && (
            <ClubStats
              eventCount={data.eventCountThisYear}
              capacity={data.capacity}
              artists={data.topArtists}
            />
          )}
          {data.blurb && <ClubRaSays blurb={data.blurb} raSays={data.raSays} />}
          {data.address && data.name && (
            <ClubMap
              name={data.name}
              address={data.address}
              title={`${data.name} location`}
            />
          )}
          {data.photo && (
            <FullWidthImage
              src={data.photo}
              alt={`${data.name} photo`}
              sizes={['s', 'm', 'l']}
              width="100%"
            />
          )}
          <ClubNews id={data.id} />
        </PaddedStack>

        <Padded>
          <ClubEventsArchive id={data.id} />
        </Padded>
        <ClubDetailRelatedContent area={data.area} />
      </Section>
    </ClubDetailPageTemplate>
  )
}

ClubDetailMarkup.propTypes = {
  data: PropTypes.object.isRequired,
  upcomingEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
}

const buildLinks = (club) => [
  {
    type: 'Website',
    href: club.website,
  },
  {
    type: 'Maps',
    href: `http://maps.google.com/maps?q=${club.address}(${club.name})`,
  },
]

const buildAdTargeting = (id, countryName, areaName, path) => [
  [AD_TARGETING_KEYS.PAGE, path],
  [AD_TARGETING_KEYS.CATEGORY, 'Listings'],
  [AD_TARGETING_KEYS.SECTION, 'Clubs'],
  [AD_TARGETING_KEYS.CLUB, id.toString()],
  [AD_TARGETING_KEYS.COUNTRY, countryName],
  [AD_TARGETING_KEYS.AREA, areaName],
]

export { ClubDetailMarkup }
export default ClubDetail
