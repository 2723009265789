import PropTypes from 'prop-types'
import getConfig from 'next/config'
import { JsonLdHeadScript } from 'components/generic/json-ld'

const { publicRuntimeConfig } = getConfig()

const MusicVenueJsonLd = ({
  name,

  address: streetAddress,
  addressRegion,
  addressCountry,

  capacity: maximumAttendeeCapacity,
  logoUrl: logo,
  blurb: description,
  contentUrl: url,
  photo,
}) => {
  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'MusicVenue',
    address: {
      '@type': 'PostalAddress',
      streetAddress,
      addressRegion,
      addressCountry,
    },
    name,
    url: `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${url}`,
    description,
    logo,
  }

  const maximumCapacityInt = parseInt(maximumAttendeeCapacity)
  if (maximumCapacityInt) {
    jsonLd.maximumAttendeeCapacity = maximumCapacityInt
  }

  if (photo) {
    jsonLd.image = [photo]
  }

  return <JsonLdHeadScript properties={jsonLd} scriptKey="jsonld-musicvenue" />
}

MusicVenueJsonLd.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  addressRegion: PropTypes.string.isRequired,
  addressCountry: PropTypes.string.isRequired,
  capacity: PropTypes.string,
  blurb: PropTypes.string,
  logoUrl: PropTypes.string,
  contentUrl: PropTypes.string.isRequired,
  photo: PropTypes.string,
}

export default MusicVenueJsonLd
