import PropTypes from 'prop-types'
import { SubSection } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import messages from 'messages/dict'
import GoogleMap from 'components/generic/google-map'

const ClubMap = ({ address, name, title }) => {
  const intl = useIntl()

  return (
    <SubSection.Stacked title={intl.formatMessage(messages.location)}>
      <GoogleMap address={address} name={name} title={title} />
    </SubSection.Stacked>
  )
}

ClubMap.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default ClubMap
