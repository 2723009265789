import { useRouter } from 'next/router'
import ClubDetail from 'components/events/pages/club-detail/ClubDetail'
import WithDefaultLayout from 'components/generic/layouts/default'
import Throw404 from 'components/generic/throw-404'
import isValidId from 'lib/validId'
import ENTITY_TYPE from 'enums/entity-type'

const Club = () => {
  // Matches: /clubs/[id]
  const {
    query: { idOrCountry },
  } = useRouter()

  if (!isValidId(idOrCountry)) return <Throw404 entityType={ENTITY_TYPE.Club} />

  return <ClubDetail id={idOrCountry} />
}

export default WithDefaultLayout(Club)
