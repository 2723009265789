import { SubSection, Grid, Column } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import messages from 'messages/events'
import dict from 'messages/dict'
import CmsContent from 'components/generic/cms-content'
import { light } from 'themes'

const ClubRaSays = ({ blurb, raSays }) => {
  const intl = useIntl()

  return (
    <ThemeProvider theme={light}>
      <SubSection.Stacked
        title={intl.formatMessage(raSays ? messages.raSays : dict.about)}
      >
        <Grid lCols={12}>
          <Column lSpan={7}>
            <CmsContent content={blurb} />
          </Column>
        </Grid>
      </SubSection.Stacked>
    </ThemeProvider>
  )
}

ClubRaSays.propTypes = {
  blurb: PropTypes.string.isRequired,
  raSays: PropTypes.bool,
}

export default ClubRaSays
