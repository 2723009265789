import { guideByAreaRoute } from 'lib/routes'
import { generateUrlForAreaAndCountry, getAreaSafeName } from 'lib/utils'

const getGuideLinkProps = (venue) =>
  guideByAreaRoute(generateUrlForAreaAndCountry('/guide', venue.area))

const getGuideLocationText = (venue) =>
  getAreaSafeName(venue.area, venue.area.country)

export { getGuideLinkProps, getGuideLocationText }
