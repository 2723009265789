import PropTypes from 'prop-types'
import { Section, Stack, Text, variants } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { dark } from 'themes'
import { useIntl } from 'react-intl'
import { PROFILE_IMAGE_TYPES } from 'lib/profileImageUtils'
import { getGuideLinkProps } from 'components/events/pages/club-detail/getVenueBreadcrumb'
import { BreadcrumbFlagIcon } from 'components/generic/breadcrumb/Breadcrumb'
import ProfileHeader from 'components/shared/profile-header'
import messages from 'messages/events'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import useMetadataOverrides from 'hooks/useMetadataOverrides'
import Seo from 'components/generic/seo/Seo'
import useLocalisedAreaName from 'hooks/useLocalisedAreaName'
import { useClubSEO, CLUB_PAGE_TYPE } from './clubSEOUtils'

const ClubDetailPageTemplate = ({ children, venue, noIndex, pageType }) => {
  const intl = useIntl()

  const { href: guideHref, as: guideAs } = getGuideLinkProps(venue)

  const { area } = venue

  const guideLocationText = useLocalisedAreaName({
    area,
    country: area.country,
  })

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.viewClubPage,
      properties: {
        'Club ID': venue.id,
        'Club Name': venue.name,
      },
    },
  })

  const metadataOverrides = useMetadataOverrides()

  const seo = useClubSEO({
    data: venue,
    noIndex,
    pageType,
    overrides: {
      title: metadataOverrides?.title,
      description: metadataOverrides?.description,
    },
  })

  return (
    <>
      <Seo {...seo} />
      <Section>
        <ThemeProvider theme={dark}>
          <ProfileHeader
            text={venue.name}
            backgroundImage={venue.photo}
            smallImage={venue.logoUrl}
            smallImageType={PROFILE_IMAGE_TYPES.logo}
            isArchivedPage={venue.isClosed}
            breadcrumb={[
              {
                href: guideHref,
                as: guideAs,
                text: guideLocationText,
                icon: (
                  <BreadcrumbFlagIcon urlCode={venue.area.country.urlCode} />
                ),
              },
            ]}
          />
          {venue.isClosed && (
            <Stack bg="black" py={3}>
              <Text variant={variants.text.heading.m}>
                {intl.formatMessage(messages.thisClubIsPermanentlyClosed)}
              </Text>
            </Stack>
          )}
        </ThemeProvider>

        {children}
      </Section>
    </>
  )
}

ClubDetailPageTemplate.propTypes = {
  children: PropTypes.node,
  venue: PropTypes.object.isRequired,
  noIndex: PropTypes.bool,
  pageType: PropTypes.oneOf(Object.values(CLUB_PAGE_TYPE)),
}

export default ClubDetailPageTemplate
