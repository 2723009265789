import { getAreaSafeName } from 'lib/utils'
import { useIntl, MessageDescriptor } from 'react-intl'
import useSeo from 'hooks/useSeo'
import VenueDto from 'interfaces/gql/VenueDto'
import AreaDto from 'interfaces/gql/AreaDto'
import seo from 'messages/seo'
import useLocalisedAreaName from 'hooks/useLocalisedAreaName'

const CLUB_PAGE_TYPE = {
  overview: 'overview',
  upcomingEvents: 'upcomingEvents',
  pastEvents: 'pastEvents',
  news: 'news',
}

type ClubPageType = keyof typeof CLUB_PAGE_TYPE

type MessageInput = {
  message: MessageDescriptor
  values?: Record<string, unknown>
}

const useClubSEO = ({
  data,
  noIndex = false,
  pageType,
  overrides,
}: ClubSEOInput) => {
  const { area, logoUrl, name, blurb } = data
  const images = []

  const region = useLocalisedAreaName({ area, country: area.country })

  if (logoUrl) {
    images.push({ url: logoUrl })
  }

  const { getTitle, getSharingTitle, getDescription } = useSeoMessages()

  const clubDescription =
    getDescription(data, region, pageType) || 'RA: Resident Advisor'

  const titleMessage = getTitle(name, area, region, pageType)

  return useSeo({
    titleMessage,
    overrides: {
      title: overrides?.title,
      description: overrides?.description || clubDescription,
      ogTitle: overrides?.title || getSharingTitle(name, region, pageType),
      ogDescription: overrides?.description || blurb || 'RA: Resident Advisor',
      images,
      noindex: noIndex,
    },
  })
}

type ClubSEOInput = {
  data: VenueDto
  noIndex: boolean
  pageType: ClubPageType
  overrides?: {
    title?: string
    description?: string
  }
}

const useSeoMessages = () => {
  const intl = useIntl()
  const getTitle = (
    name: string,
    area: AreaDto,
    region: string,
    pageType: ClubPageType
  ): MessageInput => {
    switch (pageType) {
      case CLUB_PAGE_TYPE.overview:
        if (name.includes(region)) {
          return {
            message: seo.clubPageTypeOverviewRegionTitle,
            values: { name },
          }
        }
        return {
          message: seo.clubPageTypeOverviewTitle,
          values: {
            name,
            region,
          },
        }
      case CLUB_PAGE_TYPE.upcomingEvents:
        if (name.includes(region)) {
          return {
            message: seo.clubPageTypeUpcomingEventsRegionTitle,
            values: { name },
          }
        }

        return {
          message: seo.clubPageTypeUpcomingEventsTitle,
          values: { name, region },
        }
      case CLUB_PAGE_TYPE.pastEvents:
        if (name.includes(region)) {
          return {
            message: seo.clubPageTypePastEventsRegionTitle,
            values: { name },
          }
        }

        return {
          message: seo.clubPageTypePastEventsTitle,
          values: { name, region },
        }
      case CLUB_PAGE_TYPE.news:
        if (name.includes(region)) {
          return {
            message: seo.clubPageTypeNewsRegionTitle,
            values: { name },
          }
        }

        return {
          message: seo.clubPageTypeNewsTitle,
          values: { name, region },
        }
      default:
        return {
          message: {
            id: 'defaultClubPageType',
            defaultMessage: '',
          },
        }
    }
  }

  const getSharingTitle = (
    name: string,
    region: string,
    pageType: ClubPageType
  ) => {
    let tabTitle: string

    switch (pageType) {
      case CLUB_PAGE_TYPE.overview:
        tabTitle = region
        break
      case CLUB_PAGE_TYPE.upcomingEvents:
        tabTitle = intl.formatMessage(seo.clubTabTitleUpcomingEvents)
        break
      case CLUB_PAGE_TYPE.pastEvents:
        tabTitle = intl.formatMessage(seo.clubTabTitlePastEvents)
        break
      case CLUB_PAGE_TYPE.news:
        tabTitle = intl.formatMessage(seo.clubTabTitleNews)
        break
      default:
        tabTitle = ''
    }

    return tabTitle
      ? intl.formatMessage(seo.clubSharingTitleDefault, { name, tabTitle })
      : intl.formatMessage(seo.clubSharingTitleAlt, { name })
  }

  const getDescription = (
    data: VenueDto,
    region: string,
    pageType: ClubPageType
  ) => {
    const { name, area, blurb } = data

    const regionIsInName =
      name?.includes(region) ||
      (area && name?.includes(getAreaSafeName(area, area.country)))

    switch (pageType) {
      case CLUB_PAGE_TYPE.overview: {
        let description = intl.formatMessage(seo.clubPageOverviewDescription, {
          name: data.name,
          region,
        })

        if (regionIsInName) {
          description = intl.formatMessage(
            seo.clubPageOverviewDescriptionWithRegion,
            { name: data.name }
          )
        }
        return getBlurbSafeDescription(description, blurb)
      }
      case CLUB_PAGE_TYPE.upcomingEvents: {
        let description = intl.formatMessage(
          seo.clubPageUpcomingEventsDescription,
          { name: data.name, region }
        )

        if (regionIsInName) {
          description = intl.formatMessage(
            seo.clubPageUpcomingEventsDescriptionWithRegion,
            { name: data.name }
          )
        }

        return getBlurbSafeDescription(description, blurb)
      }
      case CLUB_PAGE_TYPE.pastEvents: {
        const description = intl.formatMessage(seo.clubPagePastEvents, {
          name: data.name,
        })

        return getBlurbSafeDescription(description, blurb)
      }
      case CLUB_PAGE_TYPE.news: {
        const description = intl.formatMessage(seo.clubPageNews, {
          name: data.name,
        })

        return getBlurbSafeDescription(description, blurb)
      }
      default:
        return ''
    }
  }

  const getBlurbSafeDescription = (description: string, blurb: string) =>
    blurb ? `${description} ${blurb}` : description

  return {
    getTitle,
    getSharingTitle,
    getDescription,
  }
}

export { useClubSEO, CLUB_PAGE_TYPE }
