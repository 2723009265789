import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import Link from 'components/generic/link'

const ClubLinks = ({ links }) => (
  <Alignment flexDirection="row" flexWrap="wrap">
    {links.map((link, index) => (
      <Fragment key={index}>
        <Link variant={variants.text.small} underline href={link.href}>
          {link.type}
        </Link>
        {index < links.length - 1 && (
          <Text variant={variants.text.small} color="primary">
            ,&nbsp;
          </Text>
        )}
      </Fragment>
    ))}
  </Alignment>
)

ClubLinks.propTypes = {
  links: PropTypes.array,
}

export default ClubLinks
