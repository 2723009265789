import { gql } from '@apollo/client'

const GET_VENUE = gql`
  query GET_VENUE($id: ID!) {
    venue(id: $id) {
      id
      name
      logoUrl
      photo
      blurb
      address
      isFollowing
      contentUrl
      phone
      website
      followerCount
      capacity
      raSays
      isClosed
      topArtists {
        name
        contentUrl
      }
      eventCountThisYear
      area {
        id
        name
        urlName
        country {
          id
          name
          urlCode
          isoCode
        }
      }
    }
  }
`

export default GET_VENUE
