import { gql } from '@apollo/client'

const GET_VENUE_NEWS = gql`
  query GET_VENUE_NEWS($id: ID!) {
    venue(id: $id) {
      id
      contentUrl
      news(limit: 3) {
        blurb
        date
        imageUrl
        id
        title
        contentUrl
      }
    }
  }
`

export default GET_VENUE_NEWS
