import PropTypes from 'prop-types'
import SubNav from 'components/generic/sub-nav'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import { variants } from '@resident-advisor/design-system'
import trackingIds from 'tracking/tracking-ids'
import {
  clubEventsByIdRoute,
  clubPastEventsByIdRoute,
  clubByIdRoute,
  clubNewsByIdRoute,
} from 'lib/routes'

const ClubsSubNav = ({ contentUrl, subNavFilters, hasUpcomingEvents }) => {
  const intl = useIntl()
  const subNavItems = createSubNavItems(intl, contentUrl, hasUpcomingEvents)

  return (
    <SubNav
      subNavFilters={subNavFilters}
      sectionVariant={variants.section.primary}
      trackingId={trackingIds.clubDetailSubNav}
      subNavItems={subNavItems}
    />
  )
}

const createSubNavItems = (intl, contentUrl, hasUpcomingEvents) => {
  const subNavItems = [
    {
      title: intl.formatMessage(messages.overview),
      ...clubByIdRoute(contentUrl),
    },
  ]

  if (hasUpcomingEvents) {
    subNavItems.push({
      title: intl.formatMessage(messages.upcomingEventsSubnavTitle),
      ...clubEventsByIdRoute(`${contentUrl}/events`),
    })
  }

  subNavItems.push({
    title: intl.formatMessage(messages.pastEventsSubnavTitle),
    ...clubPastEventsByIdRoute(`${contentUrl}/past-events`),
  })

  subNavItems.push({
    title: intl.formatMessage(messages.raNews),
    ...clubNewsByIdRoute(`${contentUrl}/news`),
  })

  return subNavItems
}

ClubsSubNav.propTypes = {
  contentUrl: PropTypes.string.isRequired,
  subNavFilters: PropTypes.array,
  hasUpcomingEvents: PropTypes.bool,
}

export default ClubsSubNav
