import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import QueryResultHandler from 'components/generic/query-result-handler'
import HorizontalList from 'components/generic/horizontal-list'
import { ThemeProvider } from 'styled-components'
import arrayHasData from 'lib/arrayHasData'
import NewsCard from 'components/editorial/cards/news'
import messages from 'messages/events'
import { light } from 'themes'
import GET_VENUE_NEWS from './GetVenueNewsQuery'

const ClubNews = ({ id }) => {
  const props = useQuery(GET_VENUE_NEWS, {
    variables: { id },
  })

  return (
    <QueryResultHandler {...props} dataKey="venue" markup={ClubNewsMarkup} />
  )
}

ClubNews.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export const ClubNewsMarkup = ({ data }) => {
  const { formatMessage } = useIntl()

  return (
    data &&
    arrayHasData(data.news, 3) && (
      <ThemeProvider theme={light}>
        <SubSection.Stacked title={formatMessage(messages.clubNews)}>
          <HorizontalList
            Card={NewsCard}
            mCols={3}
            items={data.news.slice(0, 3)}
          />
        </SubSection.Stacked>
      </ThemeProvider>
    )
  )
}

ClubNewsMarkup.displayName = 'ClubNewsMarkup'

ClubNewsMarkup.propTypes = {
  data: PropTypes.shape({
    contentUrl: PropTypes.string.isRequired,
    news: PropTypes.array.isRequired,
  }).isRequired,
}

export default ClubNews
