import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import QueryResultHandler from 'components/generic/query-result-handler'
import EventsArchive from 'components/events/sub-sections/events-archive'
import { useRouter } from 'next/router'
import { clubPastEventsByIdRoute } from 'lib/routes'
import GET_CLUB_EVENTS_ARCHIVE from './GetClubEventsArchiveQuery'

const ClubEventsArchive = ({ id }) => {
  const { asPath } = useRouter()
  const { loading, error, data } = useQuery(GET_CLUB_EVENTS_ARCHIVE, {
    variables: { id },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="venue.events"
      markup={EventsArchive}
      linkProps={clubPastEventsByIdRoute(`${asPath}/past-events`)}
    />
  )
}

ClubEventsArchive.propTypes = {
  id: PropTypes.string.isRequired,
}

export default ClubEventsArchive
