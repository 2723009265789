import {
  SubSection,
  Grid,
  Column,
  LocationIcon,
  PersonIcon,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import StatCard, { StatValue, StatList } from 'components/events/cards/stat'
import { PropTypes } from 'prop-types'
import { light } from 'themes'

const ClubStats = ({ established, eventCount, capacity, artists }) => {
  const intl = useIntl()

  return (
    <ThemeProvider theme={light}>
      <SubSection.Stacked title={intl.formatMessage(messages.stats)}>
        <Grid sWrap mWrap lCols={8} mCols={4} sCols={2}>
          {established && (
            <Column lSpan={2} mSpan={2} sSpan={2}>
              <StatCard
                Icon={LocationIcon}
                iconLabel={intl.formatMessage(messages.established)}
              >
                <StatValue>{established}</StatValue>
              </StatCard>
            </Column>
          )}
          {eventCount !== 0 && (
            <Column lSpan={2} mSpan={2} sSpan={2}>
              <StatCard
                Icon={PersonIcon}
                iconLabel={intl.formatMessage(messages.eventsSoFarThisYear)}
              >
                <StatValue>{eventCount}</StatValue>
              </StatCard>
            </Column>
          )}
          {capacity !== '0' && capacity !== '' && (
            <Column lSpan={2} mSpan={2} sSpan={2}>
              <StatCard
                Icon={PersonIcon}
                iconLabel={intl.formatMessage(messages.capacity)}
              >
                <StatValue>{capacity}</StatValue>
              </StatCard>
            </Column>
          )}
          {artists.length > 0 && (
            <Column lSpan={2} mSpan={2} sSpan={2}>
              <StatCard
                Icon={PersonIcon}
                iconLabel={intl.formatMessage(messages.mostListedArtists)}
                list
              >
                <StatList
                  items={artists.map((artist) => ({
                    href: artist.contentUrl,
                    text: artist.name,
                  }))}
                />
              </StatCard>
            </Column>
          )}
        </Grid>
      </SubSection.Stacked>
    </ThemeProvider>
  )
}

ClubStats.propTypes = {
  capacity: PropTypes.string,
  established: PropTypes.number,
  eventCount: PropTypes.number,
  artists: PropTypes.PropTypes.arrayOf(PropTypes.object),
}

export default ClubStats
