import PropTypes from 'prop-types'
import {
  Detail,
  Section,
  Layout,
  Text,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import dict from 'messages/dict'
import { FOLLOW_BUTTON_TYPES } from 'components/shared/follow-button'
import trackingIds from 'tracking/tracking-ids'
import FollowDetail from 'components/shared/follow-detail'
import ClubLinks from './ClubLinks'

const ClubDetailBar = ({
  address,
  isFollowing,
  id,
  followerCount,
  phone,
  links,
}) => {
  const intl = useIntl()

  return (
    <Section py={4} data-tracking-id={trackingIds.clubDetailBar}>
      <Layout>
        <Grid
          lCols={12}
          mCols={8}
          sCols={4}
          gridTemplateAreas={{ s: sGTA, m: mGTA, l: lGTA }}
        >
          {address && (
            <Column area={Areas.Address}>
              <Detail title={intl.formatMessage(messages.address)}>
                <Text color="primary" variant={variants.text.small}>
                  {address}
                </Text>
              </Detail>
            </Column>
          )}
          {phone && (
            <Column area={Areas.Phone}>
              <Detail title={intl.formatMessage(dict.phone)}>
                <Text color="primary" variant={variants.text.small}>
                  {phone}
                </Text>
              </Detail>
            </Column>
          )}
          {links && links.length > 0 && (
            <Column area={Areas.Links}>
              <Detail title={intl.formatMessage(dict.links)} />
              <ClubLinks links={links} />
            </Column>
          )}
          <Column area={Areas.Followers}>
            <FollowDetail
              id={id}
              isFollowing={isFollowing}
              type={FOLLOW_BUTTON_TYPES.venue}
              followerCount={followerCount}
            />
          </Column>
        </Grid>
      </Layout>
    </Section>
  )
}

const lGTA = `
  "address address address phone phone links links . . followers followers followers"
`
const mGTA = `
  "address address phone phone links links followers followers"
`
const sGTA = `
  "address address phone phone"
  "address address links links "
  "followers followers followers . "
`

const Areas = {
  Address: 'address',
  Phone: 'phone',
  Links: 'links',
  Followers: 'followers',
}

ClubDetailBar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  address: PropTypes.string,
  phone: PropTypes.string,
  followerCount: PropTypes.number,
  isFollowing: PropTypes.bool,
  links: PropTypes.array,
}

export default ClubDetailBar
