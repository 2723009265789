import PropTypes from 'prop-types'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const GoogleMap = ({ title, address, name, height, width }) => (
  <iframe
    title={title}
    height={height}
    width={width}
    src={`https://www.google.com/maps/embed/v1/place?key=${publicRuntimeConfig.GOOGLE_MAPS_EMBED_API_KEY}&q=${name},${address}&zoom=16`}
    frameBorder={0}
    allowFullScreen
  />
)

GoogleMap.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

GoogleMap.defaultProps = {
  height: '500px',
  width: '100%',
}

export default GoogleMap
