import { gql } from '@apollo/client'

const GET_CLUB_EVENTS_ARCHIVE = gql`
  query GET_CLUB_EVENTS_ARCHIVE($id: ID!) {
    venue(id: $id) {
      id
      events(limit: 5, type: PREVIOUS) {
        id
        title
        interestedCount
        date
        isSaved
        isInterested
        contentUrl
        flyerFront
        newEventForm
        images {
          id
          filename
          alt
          type
          crop
        }
        pick {
          id
          blurb
        }
        artists {
          id
          name
        }
      }
    }
  }
`

export default GET_CLUB_EVENTS_ARCHIVE
